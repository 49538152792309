import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Badge,
  Box,
  Button,
  Card,
  Center,
  Grid,
  Group,
  Loader,
  Text,
  List,
  Modal,
  SegmentedControl,
  Divider,
  useMantineTheme,
} from "@mantine/core";
import { IconCopy } from "@tabler/icons-react";
import styled from "styled-components";
import toast from "react-hot-toast";

import {
  ColoredText,
  ScenarioNumber,
  Emphasize,
  pluralize,
} from "./UserContactTransfer";
import { UserContactTransfer } from "./";
import { CodeInput } from "@components/Auth";
import entityIcon from "@util/entityIcon";

export function formatPhone(m) {
  return {
    id: m.id,
    method: "phone",
    value: m.number_formatted,
    date: new Date(m.last_verified_at).toLocaleString(),
    status: m.status,
    used_at: m.used_at,
    raw_data: m,
  };
}

export function formatEmail(m) {
  return {
    id: m.id,
    method: "email",
    value: m.address,
    // date: new Date(m.last_verified_at).toLocaleString(),
    status: m.status,
    used_at: m.used_at,
    raw_data: m,
  };
}

{
  /* <UserContactTransfer onUpdate={fetchData} contactData={rawData} /> */
}

export default function UserContactManagement({ userId }) {
  const [items, setItems] = useState([]);
  const [method, setMethod] = useState("all");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [method]);

  function fetchData() {
    setLoading(true);
    if (method === "phone") {
      fetchPhones();
    } else if (method === "all") {
      fetchAll();
    } else {
      fetchEmails();
    }
  }

  function fetchPhones() {
    axios
      .post(`/users/${userId}/retrieve-phones/`, {
        user_id: userId,
        scope: "entire",
      })
      .then(({ data }) => {
        setItems(
          data.response
            .map((m) => formatPhone(m))
            .sort((a, b) => a.status - b.status)
        );
        setLoading(false);
      })
      .catch((err) => {
        setItems([]);
        setLoading(false);
      });
  }

  function fetchEmails() {
    axios
      .post(`/users/${userId}/retrieve-emails/`, {
        user_id: userId,
        scope: "entire",
      })
      .then(({ data }) => {
        setItems(
          data.response
            .map((m) => formatEmail(m))
            .sort((a, b) => a.status - b.status)
        );
        setLoading(false);
      })
      .catch((err) => {
        setItems([]);
        setLoading(false);
      });
  }

  function fetchAll() {
    axios
      .post(`/users/${userId}/contact-methods/`, {
        user_id: userId,
        scope: "entire",
      })
      .then(({ data }) => {
        setItems(
          data.response.map((m) =>
            m.address ? formatEmail(m) : formatPhone(m)
          )
        );
        setLoading(false);
      })
      .catch((err) => {
        setItems([]);
        setLoading(false);
      });
  }

  const filteredItems = items
    .filter((f) => true)
    .sort((a, b) => a.status - b.status);

  return (
    <React.Fragment>
      <Group>
        <SegmentedControl
          mt="sm"
          value={method}
          size="sm"
          onChange={(e) => setMethod(e)}
          data={[
            {
              label: "All",
              value: "all",
            },
            {
              label: (
                <Center>
                  {entityIcon.phone("1rem")}
                  <Box ml={10}>Phone</Box>
                </Center>
              ),
              value: "phone",
            },
            {
              label: (
                <Center>
                  {entityIcon.email("1rem")}
                  <Box ml={10}>Email</Box>
                </Center>
              ),
              value: "email",
            },
          ]}
          mb="sm"
        />
        {filteredItems.length > 0 && <CopyButton items={items} />}
        {loading && <Loader size="sm" />}
      </Group>
      {/* {method === "all" && (
        <UserContactTransfer
          contactMethods={items}
          userId={userId}
          onUpdate={fetchData}
        />
      )} */}
      <Grid>
        {filteredItems.map((item, i) => (
          <Grid.Col span={{ base: 12 }} key={item.id}>
            <ContactItem
              id={item.id}
              fetchData={item.method === "phone" ? fetchPhones : fetchEmails}
              status={item.status}
              date={item.date}
              title={item.value}
              usedAt={item.used_at}
              isPhone={item.method === "phone"}
              rawData={item.raw_data}
              userId={userId}
            />
          </Grid.Col>
        ))}
      </Grid>
    </React.Fragment>
  );
}

const ContactItem = ({
  id,
  fetchData,
  title,
  status,
  usedAt = [],
  isPhone = false,
  rawData,
  userId,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [codeValue, setCodeValue] = useState("");
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [remoteCodeId, setRemoteCodeId] = useState(null);

  const noun = isPhone ? "phone" : "email";
  const entityName = isPhone ? "phone_id" : "user_email_id";
  const isActive = status === 1;

  useEffect(() => {
    if (!init) return;

    sendCodeToMethod();
  }, [init]);

  useEffect(() => {
    if (codeValue.length === 6) {
      verifyCode();
    }
  }, [codeValue]);

  function onClose() {
    setInit(false);
    setOpen(false);
    setCodeValue("");
    setRemoteCodeId(null);
  }

  function sendCodeToMethod() {
    setLoading(true);

    const req = {
      [entityName]: id,
      activate: true,
    };

    axios
      .post(`/${noun}s/${id}/toggle-${noun}/`, req)
      .then(({ data }) => {
        setLoading(false);
        setRemoteCodeId(data.response[0].remote_code_id);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
        setInit(false);
      });
  }

  function onToggle() {
    if (status === 1) {
      if (confirm("Are you sure you want to deactivate?")) {
        deactivate();
      }
    } else {
      prepActivation();
    }
  }

  function prepActivation() {
    setOpen(true);
  }

  function verifyCode() {
    const req = {
      code: codeValue,
      remote_code_id: remoteCodeId,
    };

    axios
      .post(`/microsite/verify-code/`, req)
      .then(() => {
        activatePhone();
      })
      .catch((err) => {
        toast.error(err);
        setCodeValue("");
      });
  }

  function activatePhone() {
    const req = {
      phone_id: id,
      remote_code_id: remoteCodeId,
    };
    axios
      .post(`/${noun}s/${id}/toggle-${noun}/`, req)
      .then(() => {
        onClose();
        toast.success("Activated!");
        fetchData();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <Card>
      <Group>
        <Text size="xl" fw={600}>
          {title}
        </Text>
        <Badge
          variant="light"
          color={!isActive ? "gray" : rawData.verified ? "green" : "gray"}
        >
          {rawData.verified ? "verified" : "unverified"}
          {rawData.last_verified_at
            ? ` ${new Date(rawData.last_verified_at).toLocaleString()}`
            : ""}
        </Badge>
        {!isActive && (
          <Badge variant="light" color="red">
            Inactive
          </Badge>
        )}
      </Group>
      {rawData.validated && (
        <Group mt="1px">
          <Text c="dimmed" size="sm">
            {rawData.validated ? "validated" : "unvalidated"}
            {rawData.last_validated_at
              ? ` ${new Date(rawData.last_validated_at).toLocaleString()}`
              : ""}
          </Text>
        </Group>
      )}
      {usedAt.length > 0 && (
        <Text c="dimmed" size="sm">
          used at{" "}
          {new Intl.ListFormat("en", {
            style: "short",
            type: "conjunction",
          }).format(usedAt)}
        </Text>
      )}
      {isActive && (
        <React.Fragment>
          <Divider mt="sm" mb="sm" />
          <Group position="right">
            {status === 1 ? (
              <DeactivateButton
                userId={userId}
                id={id}
                onSuccess={fetchData}
                value={title}
                isPhone={isPhone}
              />
            ) : (
              <Button
                size="xs"
                variant="light"
                color="green"
                onClick={onToggle}
              >
                Activate
              </Button>
            )}
          </Group>
        </React.Fragment>
      )}
      <Modal opened={isOpen} onClose={onClose}>
        {init ? (
          <React.Fragment>
            <React.Fragment>
              {loading ? (
                <Group justify="center">
                  <Loader />
                </Group>
              ) : (
                <React.Fragment>
                  {remoteCodeId && (
                    <React.Fragment>
                      <Text align="center" mb="sm">
                        A message has been sent to <b>{title}</b> with a six
                        digit code. Enter the six digit code below.
                      </Text>
                      <StyledInputs>
                        <CodeInput
                          value={codeValue}
                          onChange={(e) => setCodeValue(e)}
                        />
                      </StyledInputs>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Text align="center" mb="sm">
              You're about to begin the activation process for <b>{title}</b>.
              After the button below is clicked, a code will be sent.
            </Text>
            <Button fullWidth onClick={() => setInit(true)} mt="lg">
              I'm ready
            </Button>
          </React.Fragment>
        )}
      </Modal>
    </Card>
  );
};

const StyledInputs = styled.div`
  input {
    border: 1px solid #000;
    background: #eee;
  }
`;

const DeactivateButton = ({
  isPhone,
  isEmail,
  userId,
  id,
  onSuccess,
  value,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const noun = isPhone ? "phone" : "email";
  const entityName = isPhone ? "phone_id" : "user_email_id";
  const theme = useMantineTheme();
  const green = theme.colors.green[6];
  const red = theme.colors.red[6];

  useEffect(() => {
    if (!isOpen) return;

    fetchData();
  }, [isOpen]);

  function onClose() {
    setOpen(false);
  }

  function fetchData() {
    const req = {
      user_id: userId,
      [isPhone ? "phone_id" : "user_email_id"]: id,
      warning: true,
    };

    axios
      .post(`/user-contact-corrections/`, req)
      .then(({ data }) => {
        setInfo(data.response[0]);
      })
      .catch((err) => {
        setInfo(null);
      });
  }

  function onDeactivateClick() {
    const req = {
      [entityName]: id,
    };

    setLoading(true);

    axios
      .post(`/${noun}s/${id}/toggle-${noun}/`, req)
      .then(() => {
        toast.success("Deactivated!");
        onClose();
        onSuccess();
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  return (
    <React.Fragment>
      <Button
        size="xs"
        variant="light"
        color="red"
        onClick={() => setOpen(true)}
      >
        Deactivate
      </Button>
      <Modal onClose={onClose} opened={isOpen} title={value}>
        {info && (
          <React.Fragment>
            {/* 1 */}
            {info.joins_to_replace.length > 0 && info.interaction_count > 0 && (
              <React.Fragment>
                <ScenarioNumber number={1} />
                <Text mb="sm">You want to deactivate {info.start_with}.</Text>
                <Text mb="sm">
                  {info.start_with} has{" "}
                  <ColoredText
                    color={red}
                    text={`${info.interaction_count} ${pluralize(
                      "interaction",
                      info.interaction_count
                    )}`}
                  />
                  . We are able to <ColoredText color={green} text="replace" />{" "}
                  it with another {noun} for contact.
                </Text>
                <Text mb="sm">
                  {info.start_with} will be <Emphasize>deactivated</Emphasize>.{" "}
                  We will <Emphasize>replace</Emphasize> it with {info.end_with}{" "}
                  on the following:
                </Text>
              </React.Fragment>
            )}
            {/* 2 */}
            {info.joins_to_break.length > 0 && info.interaction_count > 0 && (
              <React.Fragment>
                <ScenarioNumber number={2} />
                <Text mb="sm">You want to deactivate {info.start_with}.</Text>
                <Text mb="sm">
                  This {noun} has{" "}
                  <ColoredText
                    color={red}
                    text={`${info.interaction_count} ${pluralize(
                      "interaction",
                      info.interaction_count
                    )}`}
                  />{" "}
                  and we are{" "}
                  <ColoredText text="unable to replace" color={red} /> this{" "}
                  {noun} with another.
                </Text>
                <Text mb="sm">
                  {info.start_with} will be <Emphasize>deactivated</Emphasize>.
                  This action will leave{" "}
                  <ColoredText text="incomplete contact info" color={red} /> on
                  the following:
                </Text>
              </React.Fragment>
            )}
            {/* 3 */}
            {info.joins_to_break.length > 0 && info.interaction_count === 0 && (
              <React.Fragment>
                <ScenarioNumber number={3} />
                <Text mb="sm">You want to deactivate {info.start_with}.</Text>
                <Text mb="sm">
                  This {noun} has{" "}
                  <span style={{ color: green }}>no interactions</span> and
                  there is no {noun} to replace it for contact.
                </Text>
                <Text mb="sm">
                  {info.start_with} will be <Emphasize>removed</Emphasize> from
                  its current owner. This action will leave{" "}
                  <ColoredText color={red} text="incomplete contact info" /> on
                  the following:
                </Text>
              </React.Fragment>
            )}
            {/* 4 */}
            {info.joins_to_replace.length > 0 &&
              info.interaction_count === 0 && (
                <React.Fragment>
                  <ScenarioNumber number={4} />
                  <Text mb="sm">You want to deactivate {info.start_with}.</Text>
                  <Text mb="sm">
                    This {noun} has{" "}
                    <ColoredText text="no interactions" color={green} /> and we{" "}
                    <ColoredText
                      text={`have ${isPhone ? "a" : "an"} ${noun}`}
                      color={green}
                    />{" "}
                    that can be used for contact.
                  </Text>
                  <Text mb="sm">
                    {info.start_with} will be <Emphasize>removed</Emphasize>{" "}
                    from its current owner and <Emphasize>replaced</Emphasize>{" "}
                    with {info.end_with} for the following:
                  </Text>
                </React.Fragment>
              )}
            {/* ? */}
            {info.joins_to_break.length === 0 &&
              info.joins_to_replace.length === 0 && (
                <React.Fragment>
                  {info.interaction_count > 0 ? (
                    <React.Fragment>
                      <ScenarioNumber number={5} />
                      <Text mb="sm">
                        You want to deactivate {info.start_with}.
                      </Text>
                      <Text mb="sm">
                        This {noun} has{" "}
                        <ColoredText
                          color="red"
                          text={`${info.interaction_count} ${pluralize(
                            "interaction",
                            info.interaction_count
                          )}`}
                        />{" "}
                        and{" "}
                        <ColoredText
                          text="no locations or organizations"
                          color={green}
                        />{" "}
                        will be affected.
                      </Text>
                      <Text mb="sm">
                        We will <Emphasize>deactivate</Emphasize>{" "}
                        {info.start_with}.
                      </Text>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <ScenarioNumber number={6} />
                      <Text mb="sm">
                        You want to deactivate {info.start_with}.
                      </Text>
                      <Text mb="sm">
                        This {noun} has{" "}
                        <ColoredText color={green} text="no interactions" />,
                        and{" "}
                        <ColoredText
                          color={green}
                          text="no organizations or locations"
                        />{" "}
                        will be affected by the change.
                      </Text>
                      <Text mb="sm">
                        We will <Emphasize>remove</Emphasize> {info.start_with}{" "}
                        from this user.
                      </Text>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            {info.joins_to_replace.length > 0 && (
              <List>
                {info.joins_to_replace.map((m, i) => (
                  <List.Item key={`r${i}`}>
                    {m.name} ({m.organization ? "organization" : "location"})
                  </List.Item>
                ))}
              </List>
            )}
            {info.joins_to_break.length > 0 && (
              <List>
                {info.joins_to_break.map((m, i) => (
                  <List.Item key={`r${i}`}>
                    {m.name} ({m.organization ? "organization" : "location"})
                  </List.Item>
                ))}
              </List>
            )}
            <Divider mt="lg" mb="lg" />
          </React.Fragment>
        )}
        <Button fullWidth onClick={onClose} mt="lg">
          Get me out of here
        </Button>
        <Group justify="center" mt="xs">
          <Button
            size="xs"
            variant="subtle"
            color="gray"
            loading={loading}
            onClick={onDeactivateClick}
          >
            Confirm
          </Button>
        </Group>
      </Modal>
    </React.Fragment>
  );
};

const CopyButton = ({ items }) => {
  function onClick() {
    const phones = items
      .filter((m) => m.method === "phone")
      .map((m) => m.value);
    const emails = items
      .filter((m) => m.method === "email")
      .map((m) => m.value);
    const formattedString = `${
      phones.length ? `Phones: ${phones.join(", ")}` : ""
    }${phones.length && emails.length ? "\n\n" : ""}${
      emails.length ? `Emails: ${emails.join(", ")}` : ""
    }`;
    navigator.clipboard.writeText(formattedString);
    toast.success("Copied!");
  }

  return (
    <Button
      size="xs"
      radius="md"
      variant="light"
      onClick={onClick}
      leftSection={<IconCopy style={{ width: "70%", height: "70%" }} />}
    >
      Copy
    </Button>
  );
};
